import { useSelector } from "react-redux";

export const useVideos = () => {
    const sessions = useSelector(state => state.videos.filter(video => video.category==='Sessions'));
    const granderVision = useSelector(state => state.videos.filter(video => video.category==='Grander Vision'));
    const bonus = useSelector(state => state.videos.filter(video => video.category==='Bonus Resources'));

    return {
        sessions,
        granderVision,
        bonus
    }
}