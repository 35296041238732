import { withNamespaces } from 'react-i18next';
import { useEffect } from "react";
import { useSelector } from 'react-redux';

const Copyright = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.remove(darkTheme ? 'bg-landing' : 'bg-landing-light');
        document.body?.classList.add(darkTheme ? 'bg-dark' : 'bg-white');
    });

    return (
        <div className='container' style={{ minHeight: '100vh' }}>
            <div className="card p-4 p-md-5 m-4 m-md-5">
                <div className='text-justify'>
                    {/* begins CONTENT RESPONSE */}
                    <h2>Copyright</h2>
                    <p>&nbsp;</p>
                    <p>&reg;️ &amp; &copy;️ 2021&nbsp;Global Leadership Network. P.O. Box 3188, Barrington, IL 60010, U.S.A.
                    All rights reserved. Unauthorized duplication and distribution prohibited.</p>

                    <p>Warning: No copies of this program, in whole or in part, may be made for any
                    reason or purpose whatsoever. A limited license for public exhibition for up to
                    25 people is granted to churches or organizations so long as no fee of any kind is charged.</p>

                    <p>Using this content for training events of more than 25 people constitutes a private view event, and requires separate licensing and permissions. Please contact local GLS Committee office for more information about &nbsp;permission request.</p>

                    <p>Commercial use of this program, including conferences, conventions, internet, broadcast, and fundraising is strictly prohibited.</p>

                    <p>&nbsp;</p>

                    <h2>Derechos de Autor</h2>
                    <p>&nbsp;</p>
                    <p>&copy; 2021 Global Leadership Network. P.O. Box 3188, Barrington, IL 60010, EE.UU. Todos los derechos reservados. La distribuci&oacute;n y duplicaci&oacute;n sin autorizaci&oacute;n est&aacute; prohibida. <br/><br/>Advertencia: Ninguna copia de este programa, en parte o en su totalidad, podr&aacute; ser hecha por cualquier raz&oacute;n o prop&oacute;sito. Una licencia limitada es otorgada para usar en reuniones de hasta 25 personas a iglesias y organizaciones, siempre y cuando no se cobre. <br/><br/>El uso de este material para eventos de capacitaci&oacute;n de m&aacute;s de 25 personas constituye un evento privado y requiere otro tipo de licencia y permisos. Favor de escribir un email a contact@glsnow.app para obtener m&aacute;s informaci&oacute;n sobre obtener permisos. <br/><br/> El uso comercial de este programa, incluyendo conferencias, convenciones, internet, medios de comunicaci&oacute;n y recaudaci&oacute;n de fondos est&aacute; estrictamente prohibido</p>

                    <p>&nbsp;</p>
                    {/* ends CONTENT RESPONSE */}
                </div>
            </div>
        </div>
    )
}

export default withNamespaces()(Copyright);