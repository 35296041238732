import { useNavigate, useParams } from "react-router-dom";
import { VideosList } from '../Components';
import { withNamespaces } from 'react-i18next';
import { useSelector } from "react-redux";
import { useEffect } from "react";

const VideosCategory = ({ t }) => {
    const params = useParams()
    const codes = useSelector(state => state.user.codes)
    const darkTheme = useSelector(state => state.darkTheme)
    const navigate = useNavigate()

    useEffect(() => {
        if (codes?.length === 0) navigate("/profile")
    }, [codes])

    const selectedCategory = () => {
        switch (params.category) {
            case 'sessions':
                return t('sessions');
            case 'grander_vision':
                return t('grander-vision');
            default:
                return t('bonus-resources');
        }
    }
 
    return (
        <div className="mt-1">
            <br/>
            <h1 className={`${darkTheme && 'text-white'} ms-3 mt-3 GLSFontSecondary text-uppercase`}>{selectedCategory()}</h1>
            <br/>
            <VideosList category={params.category}/>
            <br/>
            <br/>
        </div>
    )
}

export default withNamespaces()(VideosCategory);