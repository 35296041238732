import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logoGLN from '../assets/img/logoleadership.png';
import logoGLNLight from '../assets/img/logoLeadershipDark.png';

const Footer = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <div className='full-footer'>
            <div className={`d-none d-md-block col-3 mt-3 ${darkTheme ? 'bg-success' : 'bg-primary'}`} style={{height: '8px'}}></div>
            <footer className="footer row align-items-center justify-content-between col-12 pt-2 pb-2 ps-0 pe-0 pe-sm-0 m-0">
                <div className={`d-none d-md-block col-0 col-md-1 p-0 ${darkTheme ? 'bg-success' : 'bg-primary'}`} style={{height: '50px'}}></div>
                <div className='d-flex align-items-center justify-content-around col-12 col-md-6 col-xl-4 GLSFontBold my-4 my-md-0'>
                    <img className='pe-0' src={darkTheme ? logoGLN : logoGLNLight} alt='GLN' style={{width: '137px', height: '54px'}}/>
                    <div className='ps-2'>
                        <div className={`text-left ${darkTheme ? 'text-success' : 'text-black'}`}>{t('footer-label-1')}</div>
                        <div className={`text-left ${darkTheme ? 'text-success' : 'text-black'}`}>{t('footer-label-2')}</div>
                    </div>
                </div>
                <div className={`d-flex justify-content-end align-items-center justify-content-around justify-content-md-end col-12 col-md-5 col-xl-7 pe-5 fst-italic ${darkTheme ? 'bg-success' : 'bg-primary'}`} style={{height: '50px'}}>
                    <Link to='/faqs' className={`GLSFontBold text-xs text-white`}>{t('FAQs')}</Link>
                    <Link to='/copyright' className={`ms-3 GLSFontBold text-xs text-white`}>{t('copyright')}</Link>
                    <Link to='/terms-of-use' className={`ms-3 GLSFontBold text-xs text-white`}>{t('terms-of-use')}</Link>
                    <Link to='/contact-us' className={`ms-3 me-0 me-sm-5 GLSFontBold text-xs text-white`}>{t('contact-us')}</Link>
                </div>
            </footer>
            <div className={`${darkTheme ? 'bg-success' : 'bg-primary'} col-12 col-md-7 mb-4 ms-auto`} style={{right: 0, height: '8px'}}></div>
        </div>
    )
}


export default withNamespaces()(Footer);