import { withNamespaces } from 'react-i18next';
import { useEffect } from "react";
import { Selector, Button } from "../Components";
import { useSelector } from 'react-redux';

const ContactUs = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const options_contact = []

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.remove(darkTheme ? 'bg-landing' : 'bg-landing-light');
        document.body?.classList.add(darkTheme ? 'bg-dark' : 'bg-white');
    });

    return (
        <div className='container' style={{ minHeight: '100vh' }}>
            <div className="card overflow-visible p-4 p-md-5 m-4 m-md-5">
                <h2>{t("contact-us")}</h2>
                <form className="col-12 mt-5 d-flex flex-column align-items-center px-5">
                    <input className="form-control mb-4" placeholder={t("first-name")}/>
                    <input className="form-control mb-4" placeholder={t("last-name")}/>
                    <input className="form-control mb-4" placeholder={t("email")}/>
                    <input className="form-control mb-4" placeholder={t("phone")}/>
                    <Selector placeholder={t("country")} options={options_contact} textColor='black'/>
                    <input className="form-control mb-4" placeholder={t("subject")}/>
                    <textarea className="form-control mb-4" placeholder={t("contact-message-placeholder")}/>
                    <Button >Submit</Button>
                </form>
            </div>
        </div>
    )
}

export default withNamespaces()(ContactUs);