import axios from 'axios';
import { config } from './config';

const emailTokenURL = config.server + '/create_token_with_mail/'
const codeTokenURL = config.server + '/create_token_with_code/'
const refreshURL = config.server + '/auth/jwt/refresh/'

const codeURL = config.server + '/v3/user/GET/code/'
const codeRegisterURL = config.server + '/v3/user/GET/user_code/'
const emailURL = config.server + '/v3/user/GET/exists_email/'

const createUserURL = config.server + '/auth/users/'
const addUserInfoURL = config.server + '/v3/user/PUT/profile/'
const preferedLanguageURL = config.server + '/v3/user/PUT/profileLang/'

export const getToken = async (credentials) => {
    const tokenURL = credentials.code ? codeTokenURL : emailTokenURL
    try {
        const {data} = await axios.post(tokenURL, credentials)
        return data
    } catch(e) {
        return credentials.code ? "invalid-code" : "invalid-password"
    }
}

export const refreshToken = async (token) => {
    try {
        const {data} = await axios.post(refreshURL, {refresh: token.refresh})
        return {...token, ...data}
    } catch(e) {}
}

export const verifyCode = async (code) => {
    const verifyURL = codeURL + code + '/'
    try {
        const {data} = await axios.get(verifyURL)
        return data
    } catch(e) {
        return null
    }
}

export const verifyCodeRegister = async (code) => {
    const verifyURL = codeRegisterURL + code + '/'
    try {
        const {data} = await axios.get(verifyURL)
        return data
    } catch(e) {
        return null
    }
}

export const verifyEmail = async (email) => {
    const verifyURL = emailURL + email
    try {
        const {data} = await axios.get(verifyURL)
        return data
    } catch(e) {
        return null
    }
}

export const createAccount = async ({firstname, lastname, phone, city, country, work, email, password, birthdate, gender, preferred_language}) => {
    let createAccountProsses = {errors: "No errors"}
    try {
        await axios.post(createUserURL, {email, username: email.replace('@', '_'), password})
    } catch(e) {
        createAccountProsses = {creation: e}
    }
    const token = await axios.post(emailTokenURL, {email, password})
    if (!token.access) {
        try {
            await axios.put(
                addUserInfoURL + token.data.id + '/',
                {email, first_name: firstname, last_name: lastname, phone, city, work, country, date_of_birth: birthdate, gender},
                {headers: {
                    'Authorization': `Bearer ${token.data.access}`
                }}
            )
        } catch(e) {
            createAccountProsses = {...createAccountProsses, profile: e}
        }
        try {
            await axios.patch(
                preferedLanguageURL + token.data.id + '/',
                { preferred_language },
                {headers: {
                    'Authorization': `Bearer ${token.data.access}`
                }}
            )
        } catch(e) {
            createAccountProsses = {...createAccountProsses, preferedLanguage: e}
        }
    }
    return createAccountProsses
}