import { getCountries } from '../../services';

export const initCountries = () => {
    return async (dispatch) => {
        const guides = await getCountries()

        dispatch({
            type: 'SET_COUNTRIES',
            payload: guides
        })
    }
}