import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createNote, getDownloadNote, refreshToken, updateNote } from "../services";
import { initNotes, setAlert, setToken } from '../Redux/actions';
import { Editor } from '@tinymce/tinymce-react';
import { FiFileText } from "react-icons/fi"
import parseHTML from 'react-html-parser'
import { BsFillVolumeUpFill, BsDownload } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import { BsPipFill } from "react-icons/bs"
import { FiArrowDown } from "react-icons/fi";
import descriptionBG from '../assets/img/video-descriptionBG.png'
import doc from '../assets/img/doc.png'
import cubes from '../assets/img/cuadros.png'
import notesCubes from '../assets/img/notesCube.png'
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { withNamespaces } from 'react-i18next';
import { Button } from "../Components";
import pages_num_speakers from '../assets/pages_num_speakers.js';

const Player = ({ t }) => {
    const params = useParams()
    const language = useSelector(state => state.language.selected)
    const stateVideo = useSelector(state => state.videos.filter(video => video.id === parseInt(params.videoId)));
    const video = stateVideo[0]
    const defaultTitle = video?.titles.filter(title => title.lang_code === 'eng')
    const title = video?.titles.filter(title => title.lang_code === language.key)
    const defaultDescription = video?.descriptions.filter(description => description.lang_code === 'eng')
    const description = video?.descriptions.filter(description => description.lang_code === language.key)
    const stateGuides = useSelector(state => state.guides)
    const yearGuides = stateGuides.filter(guide => guide.description === video?.year)
    const languageGuide = yearGuides.filter(guide => guide.language === language.name)
    const englishGuide = yearGuides.filter(guide => guide.language === 'English')
    const playlistGuide = languageGuide.filter(guide => guide.playlist === video.playlist_name)
    const playlistEnglishGuide = englishGuide.filter(guide => guide.playlist === video.playlist_name)
    const guide = playlistGuide[0] || playlistEnglishGuide[0]
    const guide_url = guide?.file_uri
    const languageSingleGuide = video?.language_single_file_uri.filter(guide => guide.lang_code === language.key) || []
    const defaultSingleGuide = video?.language_single_file_uri.filter(guide => guide.lang_code === 'eng') || []
    const singleGuide = languageSingleGuide[0] || defaultSingleGuide[0]

    const viewer = useRef(null);

    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        if(video) {
            window['setAudioPlayer'](video, language)
            window['setVideoPlayer'](video, language)
        }
    }, [video])

    useEffect(() => {
        window['changeLanguage'](video, language)
    }, [language])

    // Creating the PDF viewer
    useEffect(() => {
        WebViewer({
            path: '/webviewer/lib',
            initialDoc: video?.single_file_available ? singleGuide?.single_file_uri : guide_url,
            licenseKey: 'p4nff4xgnCz3r3I9jasp',
        }, viewer.current,
        ).then((instance) => {
            // now you can access APIs through the WebViewer instance
            const { Core } = instance;
            instance.UI.setTheme('dark');
            // adding an event listener for when a document is loaded
            Core.documentViewer.addEventListener('documentLoaded', () => {
              if (video && !video?.single_file_available) {
                const page_num_speaker = pages_num_speakers.filter(i => i.name === video.speaker);
                const page_num = page_num_speaker[0]["page"];
                instance.docViewer.setCurrentPage(page_num);
              }
            });
        });
    }, [video, guide_url]);

    //Notes
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const notes = useSelector(state => state.notes)
    const [selectedNote, setSelectedNote] = useState()
    const [noteTitle, setNoteTitle] = useState('')
    const noteEditorRef = useRef()
    const noteEditorTabRef = useRef()

    
    const selectNote = (event, note) => {
        event.preventDefault();
        setSelectedNote(note)
        setNoteTitle(note.title)

        noteEditorTabRef.current.click()
    }

    const saveNote = async (event) => {
        event.preventDefault()
        if(selectedNote) {
            const newToken = await refreshToken(user)
            await updateNote(newToken, selectedNote.id, noteTitle, noteEditorRef.current.getContent())
            dispatch(setToken(newToken))
            dispatch(setAlert(t('success-save-note')))
        } else {
            const newToken = await refreshToken(user)
            const newNote = await createNote(newToken, noteTitle, noteEditorRef.current.getContent())
            dispatch(setToken(newToken))
            setSelectedNote(newNote)
            dispatch(setAlert(t('success-create-note')))
        }
        dispatch(initNotes(user))
    }

    const handleDownload = async (id) => {
        const notePDF = await getDownloadNote(id)
        window.open(notePDF['PDF download URL'], "_blank");
    }

    return (
        <>
            {video &&
                <>
                    <div className="tab-content mt-0">
                        <div className="tab-pane active mb-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div id='player' />
                        </div>
                        <div className="tab-pane mb-4" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <div id='audioplayer' />
                            </div>
                        </div>
                    </div>
                    <ul className="nav d-flex d-sm-none px-2 pb-3" id="myTab" role="tablist">
                        <li className="col-4 pe-1" role="presentation">
                            <button className={`btn rounded-0 py-lg-3 text-uppercase fw-bold ${darkTheme ? 'text-white btn-outline-warning' : 'text-dark btn-outline-success'} col-12 active`}
                            id="home-tab" data-bs-toggle="tab" data-bs-target="#home" role="tab" aria-controls="home" aria-selected="true"
                            onClick={() => window['togglePlayer']('video')}>
                                <FaPlay /> {t("video")}
                            </button>
                        </li>
                        <li className="col-4 px-1" role="presentation">
                            <button className={`btn rounded-0 py-lg-3 text-uppercase fw-bold ${darkTheme ? 'text-white btn-outline-warning' : 'text-dark btn-outline-success'} col-12`}
                            id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" role="tab" aria-controls="profile" aria-selected="false"
                            onClick={() => window['togglePlayer']('audio')}>
                                <BsFillVolumeUpFill /> {t("audio")}
                            </button>
                        </li>
                        <li className="col-4 ps-1" role="presentation">
                            <button className={`btn rounded-0 py-lg-3 text-uppercase fw-bold ${darkTheme ? 'text-white btn-outline-warning' : 'text-dark btn-outline-success'} col-12`}
                            id="togglePipButtonDesktop"
                            >
                                <BsPipFill /> PiP
                            </button>
                        </li>
                    </ul>
                    <div id="videoInfoCard" className="row justify-content-between px-2 px-sm-5 ms-0 me-0">
                        <div className="card border-0 col-12 col-md-6 p-3" style={{backgroundImage: `url(${descriptionBG})`, backgroundSize: '100% 100%'}}>
                            <h2 className="text-white mb-0 GLSFontBold">{video.speaker}</h2>
                            <h4 className="text-white mt-1">{title[0]?.title || defaultTitle[0].title}</h4>
                            <p className="mt-3">{description[0]?.description || defaultDescription[0]?.description}</p>
                        </div>
                        <div className="d-flex justify-content-between col-12 col-md-6 p-0 ps-md-3 mt-3 mt-md-0">
                            {guide && <div className={`card col-12 col-sm-8 justify-content-around py-3 px-0 ${darkTheme && 'border-0'}`} style={{minHeight: '140px'}}>
                                <div>
                                    <div className="bg-secondary col-3" style={{height: '5px', marginLeft: '66.7%'}}></div>
                                    <div className="d-flex align-items-center justify-content-between my-2">
                                        <div className="bg-secondary col-3 col-md-2 col-xl-3" style={{height: '33px'}}></div>
                                        <h3 className="text-center mb-0">{guide?.title}</h3>
                                        <div className="bg-secondary col-3 col-md-2 col-xl-3" style={{height: '33px'}}></div>
                                    </div>
                                    <div className="bg-secondary col-3" style={{height: '5px',  marginLeft: '8.3%'}}></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between justify-content-md-around flex-row pt-4 pb-sm-2">
                                    <div className="d-flex align-items-center ms-3 ms-md-0">
                                        <img src={doc} alt="doc"/>
                                        <h5 className="ms-2 mb-0">{guide?.description}</h5>
                                    </div>
                                    <a target='_blank' href={guide_url} rel="noreferrer" className="mt-2 mt-md-0 me-3 me-md-0">
                                        <Button tertiary><FiArrowDown/> {guide?.download_string}</Button>
                                    </a>
                                </div>
                                <img className="position-sm-relative ms-auto me-auto me-sm-3" src={cubes} alt="cubes"/>
                            </div>}
                            <ul className="nav d-none d-sm-flex flex-column justify-content-between align-items-end col-4 ps-3" id="myTab" role="tablist">
                                <li className="col-12" role="presentation">
                                    <button className={`btn rounded-0 py-lg-3 text-uppercase fw-bold ${darkTheme ? 'text-white btn-outline-warning' : 'text-dark btn-outline-success'} col-12 active`}
                                    id="home-tab" data-bs-toggle="tab" data-bs-target="#home" role="tab" aria-controls="home" aria-selected="true"
                                    onClick={() => window['togglePlayer']('video')}>
                                        <FaPlay /> {t("video")}
                                    </button>
                                </li>
                                <li className="col-12" role="presentation">
                                    <button className={`btn rounded-0 py-lg-3 text-uppercase fw-bold ${darkTheme ? 'text-white btn-outline-warning' : 'text-dark btn-outline-success'} col-12`}
                                    id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" role="tab" aria-controls="profile" aria-selected="false"
                                    onClick={() => window['togglePlayer']('audio')}>
                                        <BsFillVolumeUpFill /> {t("audio")}
                                    </button>
                                </li>
                                <li className="col-12" role="presentation">
                                    <button className={`btn rounded-0 py-lg-3 text-uppercase fw-bold ${darkTheme ? 'text-white btn-outline-warning' : 'text-dark btn-outline-success'} col-12`}
                                    id="togglePipButtonDesktop"
                                    >
                                        <BsPipFill /> PiP
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ul className="nav nav-tabs mx-2 mx-sm-5 mt-3 border-0" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${darkTheme ? 'nav-link-dark' : 'nav-link-light'} active`} id="guides-tab" data-bs-toggle="tab" data-bs-target="#guides" type="button" role="tab" aria-controls="guides" aria-selected="true">
                                {t('discussion-guides')}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button ref={noteEditorTabRef} className={`nav-link ${darkTheme ? 'nav-link-dark' : 'nav-link-light'}`} id="notes-tab" data-bs-toggle="tab" data-bs-target="#notes" type="button" role="tab" aria-controls="notes" aria-selected="false">
                                {selectedNote ? t("update-note") : t("create-note")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${darkTheme ? 'nav-link-dark' : 'nav-link-light'}`} id="notes-tab" data-bs-toggle="tab" data-bs-target="#my-notes" type="button" role="tab" aria-controls="notes" aria-selected="false">
                                {t('notes')}
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="guides" role="tabpanel" aria-labelledby="guides-tab">
                            <div className="webviewer mx-2 mx-sm-5 bg-primary" ref={viewer}
                            style={{ height: '720px', borderBottomLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomRightRadius: '15px'}} />
                        </div>
                        <div className="tab-pane" id="notes" role="tabpanel" aria-labelledby="notes-tab">
                            <div className="bg-light overflow-visible py-5 mx-2 mx-sm-5" style={{borderBottomLeftRadius: '9px', borderBottomRightRadius: '9px'}}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="bg-info col-1" style={{height: '33px'}}></div>
                                    <h3 className="GLSFontBold m-0 px-2" style={{textTransform: 'uppercase'}}>{selectedNote ? t("update-note") : t("create-note")}</h3>
                                    <div className="bg-info col-5 col-md-7 col-lg-9" style={{height: '33px'}}></div>
                                </div>
                                <form className="col-12 px-3 px-sm-5 mt-3 d-flex flex-column align-items-center" onSubmit={saveNote}>
                                    <input className="form-control bg-light mb-4" placeholder={t('title')} value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)}/>
                                    <Editor
                                        onInit={(event, editor) => noteEditorRef.current = editor}
                                        apiKey='upz54pzr658jyrvrbgmbegryacq3a1bvkezan4oic9nyn8vn'
                                        initialValue={selectNote ? selectedNote?.text : ''}
                                        init={{
                                            width: '100%',
                                            toolbar: 'undo redo | formatselect | ' +
                                            'bold italic underline | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | '
                                        }}
                                    />
                                    <Button className="mt-3" secondary>{t("save")}</Button>
                                </form>
                            </div>
                        </div>
                        <div className="tab-pane" id="my-notes" role="tabpanel" aria-labelledby="my-notes-tab">
                            <div className="bg-light overflow-visible pt-5 pb-3 mx-2 mx-sm-5" style={{borderBottomLeftRadius: '9px', borderBottomRightRadius: '9px'}}>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="bg-info col-1" style={{height: '33px'}}></div>
                                    <h2 className="GLSFontBold m-0 px-2">{t("notes")}</h2>
                                    <div className="bg-info col-7 col-md-9" style={{height: '33px'}}></div>
                                </div>
                                <div className="row px-3 px-sm-5">
                                    {notes.map(note =>
                                        <a key={note.title} className="col-12 col-md-6 p-3 mb-2 " onClick={(event) => selectNote(event, note)} href="notes">
                                            <div className="d-flex col-12 overflow-hidden border-bottom">
                                                <div className='me-3'><FiFileText size={30}/></div>
                                                <div style={{width: '90%'}}>
                                                    <h5 className="fw-bolder mb-2">{note.title}</h5>
                                                    <p className="w-100 text-truncate" style={{maxHeight: '24px'}}>{parseHTML(note.text)}</p>
                                                    <p className="fw-bold text-success" onClick={() => handleDownload(note.id)} style={{cursor: 'pointer'}}>
                                                        <BsDownload className="me-3" size={25}/>{t("download")}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    )}
                                    <img src={notesCubes} alt="notesCube" className="ms-auto mt-auto" style={{maxWidth: '60px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default withNamespaces()(Player);