import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import logo from '../assets/img/logoGLSNow.png'
import logoLight from '../assets/img/logo-color.png'
import sec2img from '../assets/img/landing2.png'
import sec3img from '../assets/img/landing3.png'
import googleplay from '../assets/img/googleplay.png'
import appstore from '../assets/img/appstore.png'
import sec4img from '../assets/img/landing4.png'
import { Button } from "../Components";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import $ from 'jquery';
import bgimg1 from '../assets/img/landing-1920-cn-28jun-1.jpg';
import bgimg2 from '../assets/img/landing-1920-cn-28jun-2.jpg';
import bgimg3 from '../assets/img/landing-1920-cn-28jun-3.jpg';
import bgimg1Li from '../assets/img/landing-1920-blanco-28jun-1.jpg';
import bgimg2Li from '../assets/img/landing-1920-blanco-28jun-2.jpg';
import bgimg3Li from '../assets/img/landing-1920-blanco-28jun-3.jpg';
import Faqs from "./Faqs";

const Landing = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-dark' : 'bg-white');
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.remove(darkTheme ? 'bg-landing-light': 'bg-landing');
        document.body?.classList.add(darkTheme ? 'bg-landing': 'bg-landing-light');
    }, [darkTheme]);

    useEffect(() => {
        /* var backgroundImages = [bgimg1, bgimg2, bgimg3];
        var backgroundImagesLight = [bgimg1Li, bgimg2Li, bgimg3Li];
            $(function () {
                var i = 0;
                $(".bg-landing").css("background-image", "url(" + backgroundImages[i] + ")");
                $(".bg-landing-light").css("background-image", "url(" + backgroundImagesLight[i] + ")");
                setInterval(function () {
                    i++;
                    if (i == backgroundImages.length) {
                        i = 0;
                    }
                    $(".bg-landing").css("background-image", "url(" + backgroundImages[i] + ")");;
                    $(".bg-landing-light").css("background-image", "url(" + backgroundImagesLight[i] + ")");;
                }, 8000);
            }); */
    });


    return (
        <>
            <div className="pe-xl-5">
                {/* Desktop Layout */}
                <div className="d-none d-xl-block vh-100">
                    <div className="row my-2">
                        <div className="d-flex mb-5 mt-2">
                            <div className="d-flex flex-column align-items-center d-lg-block col-12 col-lg-6 pt-2" >
                                <img className="ps-5 mb-5" src={ darkTheme ? logo : logoLight} style={{maxHeight: '160px'}} alt="landingLogo"/>
                                <h1 className={`${darkTheme && 'text-white'} ms-5 mt-4`}>
                                    <p className="mb-1 text-uppercase"><b>{t("landing-header-1")}</b></p>
                                    <p className="text-uppercase"><b>{t("landing-header-2")}</b></p>
                                </h1>
                                <p className={`${darkTheme && 'text-white'} ms-5 mt-4 mb-5 w-75`}>
                                    {t("landing-label-0")} <span className="GLSFontBold">{t("landing-label-00")}</span>
                                </p>
                                <div className="ms-5 mt-4 mb-1">
                                    <Link to="/login/code" className="my-2 me-3" style={{minWidth: '200px'}}>
                                        <Button>{t("access-code-button")}</Button>
                                    </Link>
                                    <Link to="/login/email" className="my-2 ms-3" style={{minWidth: '200px'}}>
                                        <Button>{t("access-email-button")}</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Tablet & Mobile Layout */}
                <div className="d-xl-none mobile-landing gradient-mask d-flex flex-column align-items-center d-xl-block pt-5">
                    <img className="ps-5 my-5" src={ darkTheme ? logo : logoLight} style={{maxHeight: '120px'}} alt="landingLogo"/>
                    <b className={`${darkTheme && 'text-white'} text-center GLSFontBold`}>
                        <h1 className={`mt-4 landing-header-mobile mb-1 text-uppercase`}>{t("landing-header-1")}</h1>
                        <h1 className="text-uppercase landing-header-mobile">{t("landing-header-2")}</h1>
                    </b>
                    <p className={`${darkTheme && 'text-white'} landing-description mt-4 col-11 col-md-8 landing-subtitle-mobile text-center`}>
                        {t("landing-label-0")} <span className="GLSFontBold">{t("landing-label-00")}</span>
                    </p>
                    <div className="mt-4">
                        <Link to="/login/code" className="m-2" style={{minWidth: '200px'}}>
                            <Button>{t("access-code-button")}</Button>
                        </Link>
                        <div style={{ minHeight: '20px' }}/>
                        <Link to="/login/email" className="m-2" style={{minWidth: '200px'}}>
                            <Button>{t("access-email-button")}</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start my-5">
                <div className="d-none d-md-block bg-danger col-1" style={{height: '33px', marginTop: '6px'}}></div>
                <div className="col-12 col-md-4 ps-md-2 pe-md-4">
                    <div className="d-flex">
                        <div className="d-block d-md-none bg-danger col-1" style={{height: '33px'}}></div>
                        <h1 className={`ms-1 m-md-0 landing-title ${darkTheme ? 'text-white' : 'text-dark'}`}>
                            {t('section2_title')}
                        </h1>
                    </div>
                    <div className="position-relative bg-danger col-1 col-md-6" style={{height: '33px', marginLeft: 'auto', bottom: '40px', zIndex: -1}}></div>
                    <h4 className={`text-center landing-description text-md-start py-3 px-5 px-md-0 mt-0 mt-md-4 ${darkTheme ? 'text-white' : 'text-dark'}`}>
                        {t('section2_label')}<b>{t('section2_label2')}</b> {t('section2_label3')}
                    </h4>
                </div>
                <img className="col-9 col-md-7 mt-3 mt-md-0" src={sec2img} />
            </div>
            <div className="my-5">
                <div className="bg-info col-5" style={{height: '15px'}}></div>
                <div className="bg-info col-4 mt-3" style={{height: '50px'}}></div>
                <div className="d-flex flex-column align-items-center mt-4">
                    <h1 className={`text-center landing-title ${darkTheme ? 'text-white' : 'text-dark'}`}>{t('section3_title')}</h1>
                    <h3 className={`w-75 text-center landing-description mt-5 ${darkTheme ? 'text-white' : 'text-dark'}`}>
                        {t('section3_label')}<b className="bg-info">{t('section3_label2')}</b> {t('section3_label3')}
                    </h3>
                    <img className="w-100 mt-2 mt-md-0" src={sec3img} />
                    <h3 className={`position-relative landing-description text-center px-4 px-md-0 mb-5 ${darkTheme ? 'text-white' : 'text-dark'}`}><i>
                        {t('section3_label4')}
                    </i></h3>
                    <div className="d-flex align-items-center justify-content-center mb-5" style={{height: '45px'}}>
                        <img className="h-100 me-1 me-md-4" src={appstore} />
                        <img className="h-100 ms-1 ms-md-4" src={googleplay} />
                    </div>
                </div>
                <div className="bg-info col-4 mt-4 ms-auto" style={{height: '50px'}}></div>
                <div className="bg-info col-5 mt-3 ms-auto" style={{height: '15px'}}></div>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start my-5">
                <div className="d-none d-md-block bg-secondary col-1" style={{height: '33px', marginTop: '6px'}}></div>
                <div className="col-12 col-md-4 ps-md-2">
                    <div className="d-flex">
                        <div className="d-block d-md-none bg-secondary col-1" style={{height: '33px'}}></div>
                        <h1 className={`ms-1 m-md-0 landing-title ${darkTheme ? 'text-white' : 'text-dark'}`}>
                            {t('section4_title')}
                        </h1>
                    </div>
                    <div className="position-relative bg-secondary col-1 col-md-6" style={{height: '33px', marginLeft: 'auto', bottom: '40px', zIndex: -1}}></div>
                    <h4 className={`text-center landing-description px-5 px-md-0 text-md-start ${darkTheme ? 'text-white' : 'text-dark'} mt-4`}>
                        {t('section4_label')}
                    </h4>
                    <h4 className={`text-center landing-description text-md-start px-5 px-md-0 ${darkTheme ? 'text-white' : 'text-dark'} mt-4`}><b>{t('section4_label2')}</b> {t('section4_label3')}</h4>
                </div>
                <img className="col-9 col-md-7 mt-3 mt-md-0" src={sec4img} />
            </div>
            <Faqs />
        </>
    )
}

export default withNamespaces()(Landing);