export const alertReducer = (
        state = {
            introduction: "Dear GLS.now user, ",
            message: "",
            sign: "The GLS.now team"
        },
        action
    ) => {
    switch (action.type) {
        case 'SET_ALERT':
            return action.payload;
        default :
            return state;
    }
}