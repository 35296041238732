import { getGuides } from '../../services';

export const initGuides = () => {
    return async (dispatch) => {
        const guides = await getGuides()

        dispatch({
            type: 'SET_GUIDES',
            payload: guides
        })
    }
}