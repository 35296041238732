import { getVideos } from '../../services';

export const initVideos = (token) => {
    return async (dispatch) => {
        const videos = await getVideos(token)

        dispatch({
            type: 'INIT_VIDEO',
            payload: videos
        })
    }
}