import { getBanners } from '../../services';

export const initBanners = () => {
    return async (dispatch) => {
        const guides = await getBanners()

        dispatch({
            type: 'SET_BANNERS',
            payload: guides
        })
    }
}