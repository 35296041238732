import PropTypes from 'prop-types'
import './Button.css'
import { useSelector } from 'react-redux';

const Selector = ({secondary, tertiary, type, className, onClick, children, style, disabled}) => {

    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <button
            type={type}
            className={secondary && `btn px-4 py-2 rounded-0 border-1 text-uppercase fw-bold ${darkTheme ? 'secondary-btn-text btn-outline-warning' : 'secondary-btn-text-light btn-outline-success'} ` + className
                || tertiary && `btn px-4 py-2 rounded-0 border-1 text-uppercase fw-bold ${darkTheme ? 'tertiary-btn-text btn-outline-secondary' : 'tertiary-btn-text-light btn-outline-secondary'} ` + className
                || `btn px-4 py-2 rounded-0 border-1 text-uppercase fw-bold ${darkTheme ? 'primary-btn-text btn-outline-warning' : 'primary-btn-text-light btn-outline-success'} ` + className}
            onClick={onClick}
            style={{minWidth: '100px', ...style}}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

Selector.propTypes = {
    secondary: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.any
}

Selector.defaultProps = {
    secondary: false,
    type: 'submit',
    className: '',
    onClick: null,
    children: null
}

export default Selector;
