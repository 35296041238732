import axios from 'axios';
import { config } from './config'

const countriesURL = config.server + '/v3/user/GET/Countries/'

export const getCountries = async () => {
    try {
        const {data} = await axios.get(countriesURL)
        var countriesData = data.countries
        let countries = []
        for (let countryData of countriesData) {
            for (let countryId in countryData) {
                countries.push({value: countryId, label: countryData[countryId]})
            }
        }
        return countries
    } catch {
        return []
    }
}