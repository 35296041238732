import { useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const Collapse = ({id, title, content}) => {
    let [shown, setShown] = useState(false)
    const textColor = 'text-white'
    const iconColor = '#ffffff'
    const iconSize = '33px'

    return (
        <div id={id} className={`col-12 ${textColor}`}>
            <button className='col-12 btn d-flex justify-content-between border-bottom my-2' onClick={() => setShown(!shown)}>
                <h4 className={`m-0 p-0 text-start col-11 ${textColor}`}>{title}</h4>
                {shown
                    ? <AiOutlineMinus color={iconColor} size={iconSize}/>
                    : <AiOutlinePlus color={iconColor} size={iconSize}/>
                }
            </button>
            {shown && <p className='p-2'>{content}</p>}
        </div>
    )
}

export default Collapse