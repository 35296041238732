import axios from 'axios';
import { config } from './config';

const getNotesURL = config.server + '/v3/extra_features/GET/note/?user__id='
const postNotesURL = config.server + '/v3/extra_features/POST/note/'
const updateNotesURL = config.server + '/v3/extra_features/PUT/note/'
const downloadNoteURL = config.server + '/v3/extra_features/GET/print_note/'

export const getNotes = async (token) => {
    try {
        const {data} = await axios.get(getNotesURL + token.id)
        return data
    } catch(e) {
        return e
    }
}

export const createNote = async (token, title, text) => {
    try {
        const {data} = await axios.post(postNotesURL, {title, text, user: token.id}, {
            headers: {
              'Authorization': `Bearer ${token.access}`
            },
        })
        return data
    } catch(e) {
        return e
    }
}

export const updateNote = async (token, noteID, title, text) => {
    try {
        await axios.put(updateNotesURL + noteID + '/', {title, text, user: token.id}, {
            headers: {
              'Authorization': `Bearer ${token.access}`
            },
        })
        return null
    } catch(e) {
        return e
    }
}

export const getDownloadNote = async (id) => {
    try {
        const {data} = await axios.get(downloadNoteURL + id + '/')
        return data
    } catch(e) {
        return e
    }
}