import { getLanguages } from "../../services"

export const setLanguage = (language) => {
    return async (dispatch) => {
        dispatch({
            type: 'SET_LANGUAGE',
            payload: language
        })
    }
}

export const initLanguages = () => {
    return async (dispatch) => {
        const languages = await getLanguages()
        dispatch({
            type: 'INIT_LANGUAGES',
            payload: languages
        })
    }
}