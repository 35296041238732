import { useSelector } from "react-redux"
import { GuideList, Selector } from "../Components"
import { withNamespaces } from 'react-i18next';
import { useState } from "react";

const DiscussionGuides = ({ t }) => {
    const guides = useSelector(state => state.guides)
    const favLang = useSelector(state => state.language.selected.name)
    const allLangs = useSelector(state => state.language.languages)
    const darkTheme = useSelector(state => state.darkTheme)

    const [lang, setLang] = useState(favLang)
    let langsNames = []

    allLangs.forEach(lang => {
        langsNames.push({label: lang.lang, value: lang.lang})
    });

    return (
        <div className={`card py-4 mx-3 mx-md-5 my-5 ${darkTheme && 'border-0'}`}>
            <div className="d-flex mt-3 align-items-center justify-content-between">
                <div className="col-1 bg-info" style={{height: '33px'}}></div>
                <h3 className={`my-4 px-3 text-uppercase GLSFontSecondary`}>{t("discussion-guides")}</h3>
                <div className="col-8 bg-info" style={{height: '33px'}}></div>
            </div>
            <div className="px-3 mt-2"><Selector selected={lang} options={langsNames} onChange={(value) => setLang(value)} textColor="black" /></div>
            <div className="row mt-4 p-0 m-0 align-items-start w-100" style={{minHeight: '350px'}}>
                {guides.filter(guide => guide.language === lang).length > 0
                    ? <GuideList guides={guides.filter(guide => guide.language === lang)} />
                    : <div className="card d-flex align-items-center justify-content-center text-center m-auto col-11" style={{minHeight: '120px'}}>
                        {t("not-available")}
                      </div>
                }
            </div>
        </div>
    )
}

export default withNamespaces()(DiscussionGuides)