const page_num_speakers = [
    {"page":2,"name":"Craig Groeschel"},
    {"page":5,"name":"Michelle Poler"},
    {"page":9,"name":"General Stanley McChrystal"},
    {"page":11,"name":"Jamie Kern Lima"},
    {"page":14,"name":"Shola Richards"},
    {"page":17,"name":"Ibukun Awosika"},
    {"page":20,"name":"Jerry Lorenzo"},
    {"page":23,"name":"Rich Wilkerson Jr."},
    {"page":26,"name":"Dr. Henry Cloud"},
    {"page":29,"name":"Dr. Francesca Gino"},
    {"page":32,"name":"Richard Montañez"},
    {"page":35,"name":"A.R. Bernard"},
    {"page":38,"name":"Juliet Funt"},
    {"page":41,"name":"Bianca Juárez Olthoff"},
    {"page":44,"name":"Malcolm Gladwell"},
    {"page":47,"name":"Albert Tate"},
    {"page":5,"name":"Daniel Pink"},
    {"page":8,"name":"Hubert Joly"},
    {"page":12,"name":"Principal Linda Clyatt-Wayman"},
    {"page":14,"name":"Margaret Heffernan"},
];

export default page_num_speakers;