import { withNamespaces } from "react-i18next"
import { useSelector } from "react-redux"
import { Collapse } from "../Components";
import { useEffect } from "react";
import bg from '../assets/img/faqBG.png';

const Faqs = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const faqs = Array.from({length: 21}, (_, i) => i + 1)

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.remove(darkTheme ? 'bg-landing' : 'bg-landing-light');
        document.body?.classList.add(darkTheme ? 'bg-dark' : 'bg-white');
    });

    return (
        <div className="d-flex flex-column align-items-center justify-content-center py-5" style={{backgroundImage: `url(${bg})`, backgroundSize: '100% 100%'}}>
            <h2 className={`text-white`}>{t("faqs-title")}</h2>
            <div className="col-11 col-md-9 col-lg-8 mt-4">
                {faqs.map((i) =>
                    <Collapse 
                        id={i}
                        title={t(`question${i}`)}
                        content={t(`answer${i}`)}
                    />
                )}
            </div>
        </div>
    )
};

export default withNamespaces()(Faqs)