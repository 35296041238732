import { getNotes } from "../../services"

export const initNotes = (token) => {
    return async (dispatch) => {
        const notes = await getNotes(token)

        dispatch({
            type: 'SET_NOTES',
            payload: notes
        })
    }
}