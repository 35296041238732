import { FiArrowDown } from "react-icons/fi"
import PropTypes from 'prop-types'
import doc from '../assets/img/doc.png'
import cubes from '../assets/img/cuadros.png'
import { Button } from "."

const GuidesList = ({guides}) => {
    return guides?.map((guide, index) => 
        <div className="px-md-4 px-lg-5 py-2 col-xl-6">
            <div key={index} className="card p-0">
                <div className="card m-0 py-4 border-0 discussionGuideCard">
                    <div className="d-none d-md-block bg-secondary col-2" style={{height: '5px', marginLeft: '75%'}}></div>
                    <div className="d-flex align-items-center justify-content-between my-1">
                        <div className="bg-secondary col-3" style={{height: '33px'}}></div>
                        <h4 className="text-info text-center col-6 mb-0">{guide.title}</h4>
                        <div className="bg-secondary col-3" style={{height: '33px'}}></div>
                    </div>
                    <div className="d-md-none d-flex">
                        <div className="bg-secondary col-2" style={{height: '5px', marginLeft: '8.6%'}}></div>
                        <div className="bg-secondary col-2" style={{height: '5px',  marginLeft: '50%'}}></div>
                    </div>
                    <div className="d-none d-md-block bg-secondary col-2" style={{height: '5px',  marginLeft: '8.3%'}}></div>
                    <div className="d-flex flex-wrap align-items-center pt-3 px-3">
                        <div className="ms-xl-4 mb-2 mb-sm-0 d-flex flex-wrap align-items-center">
                            <img src={doc}/>
                            <h5 className="ms-3 mb-0">{guide.description}</h5>
                        </div>
                        <img className="ms-auto mt-4" src={cubes}/>
                        <a target='_blank' rel='noreferrer' href={guide?.file_uri} className="ms-2">
                            <Button tertiary><FiArrowDown className="me-2"/>{guide.download_string}</Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

GuidesList.propTypes = {
    guides: PropTypes.arrayOf(PropTypes.PropTypes.shape({
        language: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        file_uri: PropTypes.string,
        download_string: PropTypes.string
    })).isRequired
}

export default GuidesList;