import { withNamespaces } from 'react-i18next';
import { BsChevronDown } from 'react-icons/bs'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const LanguagesModalButton = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const token = useSelector(state => state.user)
    const path = useLocation()
    return (
        <button type="button" className="btn btn-transparent" data-bs-toggle="modal" data-bs-target="#languageModal">
            <span className={`${!darkTheme && (path.pathname === '/' || path.pathname.includes('login')) ? 'text-dark' : 'text-white'} GLSFontBold`} 
            style={{ textTransform: 'uppercase', textShadowOffset: {width:2, height: 2}, textShadowRadius: 5, textShadowColor: 'black', fontSize: '12px' }}>
                {t('select-language')} <BsChevronDown className={`${token.access ? 'text-warning' : 'text-success'} fa-solid`} size="15px" />
            </span>
        </button>
    )
}

export default withNamespaces()(LanguagesModalButton);