import { initVideos } from './videos';

export const login = (token) => {
    return async (dispatch) => {
        if(token.access) {
            window.localStorage.setItem(
                'GLSNToken', JSON.stringify(token)
            )
            dispatch({
                type: 'SET_TOKEN',
                payload: token
            })
            dispatch(initVideos(token))
        }
    }
}

export const logout = () => {
    return async (dispatch) => {
        window.localStorage.removeItem('GLSNToken')
        dispatch({
            type: 'SET_TOKEN',
            payload: {}
        })
    }
}

export const setToken = (token) => {
    return async (dispatch) => {
        window.localStorage.setItem('GLSNToken', JSON.stringify(token))
        dispatch({
            type: 'SET_TOKEN',
            payload: token
        })
    }
}