import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import eng from './languages/eng.json';
import spa from './languages/spa.json';
import ces from './languages/ces.json';
import cze from './languages/cze.json';
import dan from './languages/dan.json';
import est from './languages/est.json';
import fra from './languages/fra.json';
import hrv from './languages/hrv.json';
import isl from './languages/isl.json';
import nld from './languages/nld.json';
import pol from './languages/pol.json';
import swa from './languages/swa.json';
import vie from './languages/vie.json';
import amh from './languages/amh.json';
import ara from './languages/ara.json';
import ben from './languages/ben.json';
import bul from './languages/bul.json';
import cat from './languages/cat.json';
import ell from './languages/ell.json';
import fao from './languages/fao.json';
import fin from './languages/fin.json';
import hun from './languages/hun.json';
import ind from './languages/ind.json';
import khm from './languages/khm.json';
import lav from './languages/lav.json';
import lit from './languages/lit.json';
import may from './languages/may.json';
import mal from './languages/mal.json';
import mkd from './languages/mkd.json';
import mon from './languages/mon.json';
import mya from './languages/mya.json';
import nor from './languages/nor.json';
import por from './languages/por.json';
import ptp from './languages/ptp.json';
import ron from './languages/ron.json';
import rus from './languages/rus.json';
import sch from './languages/sch.json';
import slk from './languages/slk.json';
import sqi from './languages/sqi.json';
import srp from './languages/srp.json';
import swe from './languages/swe.json';
import tch from './languages/tch.json';
import tha from './languages/tha.json';
import ukr from './languages/ukr.json';

import faqs_eng from './faqs/faqs_eng.json';
import faqs_amh from './faqs/faqs_amh.json';
import faqs_ara from './faqs/faqs_ara.json';
import faqs_ben from './faqs/faqs_ben.json';
import faqs_bul from './faqs/faqs_bul.json';
import faqs_cat from './faqs/faqs_cat.json';
import faqs_cze from './faqs/faqs_cze.json';
import faqs_dan from './faqs/faqs_dan.json';
import faqs_dut from './faqs/faqs_dut.json';
import faqs_ell from './faqs/faqs_ell.json';
import faqs_est from './faqs/faqs_est.json';
import faqs_fin from './faqs/faqs_fin.json';
import faqs_fre from './faqs/faqs_fre.json';
import faqs_gre from './faqs/faqs_gre.json';
import faqs_hrv from './faqs/faqs_hrv.json';
import faqs_hun from './faqs/faqs_hun.json';
import faqs_ind from './faqs/faqs_ind.json';
import faqs_isl from './faqs/faqs_isl.json';
import faqs_lav from './faqs/faqs_lav.json';
import faqs_lit from './faqs/faqs_lit.json';
import faqs_may from './faqs/faqs_may.json';
import faqs_mkd from './faqs/faqs_mkd.json';
import faqs_mon from './faqs/faqs_mon.json';
import faqs_nor from './faqs/faqs_nor.json';
import faqs_pol from './faqs/faqs_pol.json';
import faqs_por from './faqs/faqs_por.json';
import faqs_ptp from './faqs/faqs_ptp.json';
import faqs_rum from './faqs/faqs_rum.json';
import faqs_rus from './faqs/faqs_rus.json';
import faqs_sch from './faqs/faqs_sch.json';
import faqs_slk from './faqs/faqs_slk.json';
import faqs_spa from './faqs/faqs_spa.json';
import faqs_sqi from './faqs/faqs_sqi.json';
import faqs_srp from './faqs/faqs_srp.json';
import faqs_swa from './faqs/faqs_swa.json';
import faqs_swe from './faqs/faqs_swe.json';
import faqs_tch from './faqs/faqs_tch.json';
import faqs_ukr from './faqs/faqs_ukr.json';
import faqs_vie from './faqs/faqs_vie.json';

import exl_eng from './extendedLandind/exl_eng.json';
import exl_spa from './extendedLandind/exl_spa.json';
import exl_bul from './extendedLandind/exl_bul.json';
import exl_cze from './extendedLandind/exl_cze.json';
import exl_dan from './extendedLandind/exl_dan.json';
import exl_dut from './extendedLandind/exl_dut.json';
import exl_est from './extendedLandind/exl_est.json';
import exl_fin from './extendedLandind/exl_fin.json';
import exl_fre from './extendedLandind/exl_fre.json';
import exl_ger from './extendedLandind/exl_ger.json';
import exl_gre from './extendedLandind/exl_gre.json';
import exl_hun from './extendedLandind/exl_hun.json';
import exl_ind from './extendedLandind/exl_ind.json';
import exl_lav from './extendedLandind/exl_lav.json';
import exl_lit from './extendedLandind/exl_lit.json';
import exl_pol from './extendedLandind/exl_pol.json';
import exl_por from './extendedLandind/exl_por.json';
import exl_ptp from './extendedLandind/exl_ptp.json';
import exl_rum from './extendedLandind/exl_rum.json';
import exl_rus from './extendedLandind/exl_rus.json';
import exl_sch from './extendedLandind/exl_sch.json';
import exl_slk from './extendedLandind/exl_slk.json';
import exl_swe from './extendedLandind/exl_swe.json';
import exl_ukr from './extendedLandind/exl_ukr.json';

// the translations
const resources = {
  eng: {
    translation: {...eng, ...faqs_eng, ...exl_eng}
  },
  spa: {
    translation: {...spa, ...faqs_spa, ...exl_spa}
  },
  ces: {
    translation: {...cze, ...faqs_cze, ...exl_cze}
  },
  dan: {
    translation: {...dan, ...faqs_dan, ...exl_dan}
  },
  est: {
    translation: {...est, ...faqs_est, ...exl_est}
  },
  fra: {
    translation: {...fra, ...faqs_fre, ...exl_fre}
  },
  hrv: {
    translation: {...hrv, ...faqs_hrv}
  },
  isl: {
    translation: {...isl, ...faqs_isl}
  },
  nld: {
    translation: {...nld, ...faqs_dut, ...exl_dut}
  },
  pol: {
    translation: {...pol, ...faqs_pol, ...exl_pol}
  },
  swa: {
    translation: {...swa, ...faqs_swa}
  },
  vie: {
    translation: {...vie, ...faqs_vie}
  },
  amh: {
    translation: {...amh, ...faqs_amh}
  },
  ara: {
    translation: {...ara, ...faqs_ara}
  },
  ben: {
    translation: {...ben, ...faqs_ben}
  },
  bul: {
    translation: {...bul, ...faqs_bul, ...exl_bul}
  },
  cat: {
    translation: {...cat, ...faqs_cat}
  },
  ell: {
    translation: {...ell, ...faqs_gre, ...exl_gre}
  },
  fao: {
    translation: fao
  },
  fin: {
    translation: {...fin, ...faqs_fin, ...exl_fin}
  },
  hun: {
    translation: {...hun, ...faqs_hun, ...exl_hun}
  },
  ind: {
    translation: {...ind, ...faqs_ind, ...exl_ind}
  },
  khm: {
    translation: khm
  },
  lav: {
    translation: {...lav, ...faqs_lav, ...exl_lav}
  },
  lit: {
    translation: {...lit, ...faqs_lit, ...exl_lit}
  },
  mal: {
    translation: mal
  },
  may: {
    translation: {...may, ...faqs_may}
  },
  mkd: {
    translation: {...mkd, ...faqs_mkd}
  },
  mon: {
    translation: {...mon, ...faqs_mon}
  },
  mya: {
    translation: mya
  },
  nor: {
    translation: {...nor, ...faqs_nor}
  },
  por: {
    translation: {...por, ...faqs_por, ...exl_por}
  },
  ptp: {
    translation: {...ptp, ...faqs_ptp, ...exl_ptp}
  },
  ron: {
    translation: {...ron, ...faqs_rum, ...exl_rum}
  },
  rus: {
    translation: {...rus, ...faqs_rus, ...exl_rus}
  },
  sch: {
    translation: {...sch, ...faqs_sch, ...exl_sch}
  },
  slk: {
    translation: {...slk, ...faqs_slk, ...exl_slk}
  },
  sqi: {
    translation: {...sqi, ...faqs_sqi}
  },
  srp: {
    translation: {...srp, ...faqs_srp}
  },
  swe: {
    translation: {...swe, ...faqs_swe, ...exl_swe}
  },
  tch: {
    translation: {...tch, ...faqs_tch}
  },
  tha: {
    translation: tha
  },
  ukr: {
    translation: {...ukr, ...faqs_ukr, ...exl_ukr}
  }
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'eng',
    lng: "eng",

    keySeparator: false, // we do not use keys

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
