import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initGuides, initNotes, initProfile, login, setAlert } from "../Redux/actions";
import { withNamespaces } from 'react-i18next';
import logo from '../assets/img/logoGLSNow.png'
import logoLight from '../assets/img/logo-color.png'
import {
    addCode,
    createAccount,
    getToken,
    verifyCode,
    verifyCodeRegister,
    verifyEmail,
} from "../services";
import { Button, Selector } from "../Components";
import { datadogLogs } from '@datadog/browser-logs'
import { initCheckout } from "../Redux/actions/checkout";

datadogLogs.init({
  clientToken: 'pub538b6a4002ffd16fc567d0ac4ffd9b31',
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
})

const Login = ({ t }) => {
    const token = window.localStorage.getItem('GLSNToken')
    const user = JSON.parse(token)
    const navigate = useNavigate()
    const params = useParams()
    const countries = useSelector(state => state.countries)
    const darkTheme = useSelector(state => state.darkTheme)
    const language = useSelector(state => state.language.selected)
    const legacyCode = new URLSearchParams(useLocation().search).get('code');
    const [step, setStep] = useState(legacyCode ? 'email' : params.method)
    const [code, setCode] = useState(legacyCode || params.code || '')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [work, setWork] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    const [birthdate, setBirthdate] = useState('')
    const [gender, setGender] = useState('')
    const genders = [{value: 'm', label: t('Male')}, {value: 'f', label: t('Female')}, {value: 'x', label: t('not-mentioned')}]
    const [validating, setValidating] = useState(false)

    const handleLogin = async (token) => {
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.add(darkTheme ? 'bg-dark': 'bg-white');
        dispatch(login(token))
        dispatch(initGuides())
        dispatch(initProfile(token))
        dispatch(initNotes(token))
        dispatch(initCheckout(token))
    }

    useEffect(() => {
        const URLCode = legacyCode || params.code
        
        //This async function was created because useEffect cant be an async function
        const fetchToken = async () => {    
            /*Verify if a code has been given by URL (QR code), if the code exist in the DB, and if it has a user
              if true then login directly*/
            if (URLCode && await verifyCode(URLCode) && await verifyCodeRegister(URLCode)) {
                    datadogLogs.logger.info('User info is correct, logging in', { name: 'login', id: 1});
                    handleLogin(await getToken({code: URLCode}))
                    navigate('/home')
            }
        }

        const validateIfToken = async () => {
            if (token) {
                if (user?.codes.find(item => item.code === URLCode)) {
                    navigate('/home')
                } else if (await verifyCodeRegister(URLCode)) {
                    datadogLogs.logger.info(`User code ${code} obtained from URL is already registered`, { name: 'validate token', id: 2});
                    fetchToken()
                } else {
                    datadogLogs.logger.info(`Code ${code} obtained from URL`, { name: 'URL_token', id: 3});
                    navigate(`/profile/${URLCode}`)
                }
            } else {
                fetchToken()
            }
        }
        validateIfToken()

        // dispatch(setAlert("Sorry, we are down for scheduled maintenance right now. All languages will be available soon."))
        document.body?.classList.remove(darkTheme ? 'bg-dark' : 'bg-white');
        document.body?.classList.remove(darkTheme ? 'bg-landing' : 'bg-landing-light');
        document.body?.classList.add(darkTheme ? 'bg-login' : 'bg-login-light');
    })

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-login-light': 'bg-login');
        document.body?.classList.add(darkTheme ? 'bg-login': 'bg-login-light');
    }, [darkTheme])

    const handleSubmit = async (event) => {
        event.preventDefault()
        var token
        switch(step) {
            case 'code':
                setValidating(true)
                //verify if code exist , if true continue, else error
                if (await verifyCode(code)) {
                    datadogLogs.logger.info(`The code ${code} exists in DB `, { name: 'btnCode', id: 4 });
                    //verify if code is assigned to a user, if true login, else ask for email
                    if (await verifyCodeRegister(code)) {
                        datadogLogs.logger.info(`The code ${code} is linked to an account`, { name: 'verifyCode', id: 5 });
                        handleLogin(await getToken({code}))
                    } else {
                        datadogLogs.logger.info(`The code ${code} is NOT linked to an account`, {name: 'verifyCode', id: 6 });
                        setError(null)
                        setStep('email')
                    }
                } else {
                    datadogLogs.logger.info(`Code ${code} not in DB`, {name: 'verifyCode', id: 7 });
                    setError('invalid-code')
                    window.scrollTo(0, 0)
                }
                setValidating(false)
                break
            case 'email':
                setValidating(true)
                //verify if the email is registered, if true continue to password, else error
                if (await verifyEmail(email.toLowerCase())) {
                    datadogLogs.logger.info(`The email ${email} is registered`, { name: 'verifyEmail', id: 8 });
                    setError(null)
                    setStep('password')
                } else {
                    datadogLogs.logger.info(`The email ${email} is NOT registered`, {name: 'verifyEmail', id: 9 });
                    setError('invalid-email')
                    window.scrollTo(0, 0)
                }
                setValidating(false)
                break
            case 'password':
                setValidating(true)
                //try to get token, if no token send error
                token = await getToken({ email: email.toLowerCase(), password })
                if(token.access) {
                    setError(null)
                    //if user is trying to add a code ask for confirmation
                    if(code) {
                        datadogLogs.logger.info(`Adding code ${code}`, { name: 'btnPassword', id: 10 });
                        setStep('confirm')
                    }
                    else handleLogin(token)
                } else {
                    datadogLogs.logger.info('Wrong password', { name: 'btnPassword', id: 11 });
                    setError('invalid-password')
                    window.scrollTo(0, 0)
                }
                setValidating(false)
                break
            case 'sign':
                setValidating(true)
                // verify all data has been provided
                if (email && password && firstname && lastname && phone && work && city && country && birthdate && gender) {
                    if (/\S+@\S+\.\S+/.test(email)) {
                        const emailExtract = email.toLowerCase().split("@")
                        if (password.length > 7 && !password.includes(email)) {
                            //verify if email has been registered before, if true login, else ask for confirmation
                            if(await verifyEmail(email.toLowerCase())) {
                                token = await getToken({ email: email.toLowerCase(), password })
                                if(token.access) {
                                    //if user is trying to add a code ask for confirmation
                                    if(code) {
                                        datadogLogs.logger.info(`Adding code ${code}`, { name: 'btnSign', id: 12 });
                                        setError(null)
                                        setStep('confirm')
                                    }
                                    else handleLogin(token)
                                } else {
                                    datadogLogs.logger.info(`Tried to sign-in with registered email ${email}, but wrong password`, { name: 'btnSign', id: 13 });
                                    setError('valid-email-invalid-password')
                                    window.scrollTo(0, 0)
                                }
                            } else {
                                datadogLogs.logger.info(`Registering account for ${email} userName: ${email.replace('@', '_')}`, { name: 'btnSign', id: 14 });
                                setError(null)
                                setStep('confirm')
                            }
                        } else {
                            if (password.includes(emailExtract)) {
                                datadogLogs.logger.info(`Invalid password lenght for ${email}`, {name: 'btnSign', id: 15 });
                                setError('invalid-password-inclution')
                            } else {
                                datadogLogs.logger.info(`Invalid password lenght for ${email}`, {name: 'btnSign', id: 15 });
                                setError('invalid-password-lenght')
                            }
                            window.scrollTo(0, 0)
                        }
                    } else {
                        datadogLogs.logger.info(`Invalid password lenght for ${email}`, {name: 'btnSign', id: 15 });
                        setError('invalid-email-structure')
                        window.scrollTo(0, 0)
                    }
                } else {
                    datadogLogs.logger.info(`Incomplete user info to register ${email}`, {name: 'btnSign', id: 16 });
                    setError('invalid-info')
                    window.scrollTo(0, 0)
                }
                setValidating(false)
                break
            case 'confirm':
                setValidating(true)
                //verify if we have all data, if true then we come from "create account form" 
                //else we come from trying to add a code to an existing account
                if (email && password && firstname && lastname && phone && work && city && country) {
                    //verify if email has been registered before, if false create account
                    token = await getToken({ email: email.toLowerCase(), password })
                    if(!token?.access) {
                        const creationErrors= await createAccount({firstname, lastname, country, phone, city, work, email: email.toLowerCase(), password, birthdate, gender, preferred_language: language.id})
                        datadogLogs.logger.error(creationErrors, {});
                        //we get the token of the new created account in case of needing to add a code
                        token = await getToken({ email: email.toLowerCase(), password })
                    }
                    //if user is trying to add a code add it
                    if (code) {
                        await addCode(token, code)
                        token = await getToken({ email: email.toLowerCase(), password })
                    }
                    //login
                    handleLogin(token)
                }
                else {
                    await addCode(token, code)
                    handleLogin(await getToken({ email: email.toLowerCase(), password }))
                }
                setValidating(false)
                break
            default:
                console.log("Not managed " + step)
        }
    }

    return (
        <div className="d-flex flex-column align-items-center justify-content-center py-5 pb-5 my-5">
            <Link className="col-5" to='/'>
                <img id="GLSNowLogo" className="img-fluid" src={darkTheme ? logo : logoLight} alt="pageLogo" style={{maxWidth: '200px', margin: 'auto', display: 'block'}} />
            </Link>
            <h1 className="text-secondary mt-5 GLSFontSecondary">{t("welcome")}</h1>
            <form className="col-11 col-sm-7 col-md-6 col-lg-3 mt-6" onSubmit={handleSubmit}>
                <center>
                    {/* The next part is the message indicating you are activating a code */}
                    {step !== 'code' && step !== 'confirm' && code && 
                        <p className="alert bg-white text-primary mt-3 mb-4">
                            {t("code-activation-notification")} <span className="GLSFontBold"> {code} </span>
                        </p>
                    }
                    {/* The next part is a small label indicating the step the user is at */}
                    <p className={`${darkTheme && 'text-white'} mb-3 mt-3`}>
                        {t(`access-label-${step}`)}
                    </p>
                    {/* The next line is the error message */}
                    {error && <p className={`${darkTheme && 'text-white'} alert bg-danger mb-3`}>{t(error)}</p>}
                    {step === 'code' &&
                        <input type="text" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`} id="code"
                            placeholder={t("code")} value={code} onChange={(e) => setCode(e.target.value)}
                        />
                    }
                    {step === 'email' && 
                        <input type="email" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`} id="email"
                            placeholder={t("mail")} value={email} onChange={(e) => setEmail(e.target.value)}
                        />
                    }
                    {step === 'password' && 
                        <input type="password" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`} id="password"
                            placeholder={t("password")} value={password} onChange={(e) => setPassword(e.target.value)}
                        />
                    }
                    {step === 'sign' &&
                        <>
                            {/* This is the form for creating an account */}
                            <input type="email" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`}
                                placeholder={t("mail")} value={email} onChange={(e) => setEmail(e.target.value)}
                            />
                            <input type="text" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`}
                                placeholder={t("first-name")} value={firstname} onChange={(e) => setFirstName(e.target.value)}
                            />
                            <input type="text" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`}
                                placeholder={t("last-name")} value={lastname} onChange={(e) => setLastName(e.target.value)}
                            />
                            <div className="input-group mb-3">
                                <span className={`${darkTheme && 'text-white bg-dark'} input-group-text`} id="basic-addon1">{t('birthdate')}</span>
                                <input type="date" className={`${darkTheme && 'text-white'} form-control bg-transparent`} style={{colorScheme: 'dark'}}
                                value={birthdate} onChange={(e) => setBirthdate(e.target.value)}/>
                            </div>
                            <Selector placeholder={t('gender')} options={genders} onChange={(value) => setGender(value)} />
                            <input type="text" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`}
                                placeholder={t("phone")} value={phone} onChange={(e) => setPhone(e.target.value)}
                            />
                            <Selector placeholder={t('country')} options={countries} onChange={(value) => setCountry(value)} />
                            <input type="text" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`}
                                placeholder={t("city")} value={city} onChange={(e) => setCity(e.target.value)}
                            />
                            <input type="text" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`}
                                placeholder={t("occupation")} value={work} onChange={(e) => setWork(e.target.value)}
                            />
                            <input type="text" className={`${darkTheme && 'text-white'} form-control bg-transparent mb-4`} id="password"
                                placeholder={t("password")} value={password} onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button type="button" className="mx-1 my-2" onClick={() => {setStep('email'); setError(null)}}>
                                {t("back")}
                            </Button>
                        </>
                    }
                    {step === 'confirm' &&
                        <>
                            {/* The next text adjusts when adding a code or just creating an account */}
                            {code 
                                ? <p className="alert bg-white mt-3 mb-3">
                                    {t('confirmation-label-1')} <span className="GLSFontBold"> {code} </span><br/>{t('confirmation-label-2')} <span className="GLSFontBold"> {email} </span> ?
                                </p>
                                : <h5 className="alert bg-white mt-3 mb-3">{t('create-account-confirmation-label')} <span className="GLSFontBold"> {email} </span></h5>
                            }
                            {/* The next button is used to cancel the create account or add code process */}
                            <Link to='/' className="me-2">
                                <Button>{t("cancel")}</Button>
                            </Link>
                        </>
                    }
                    {/* The next button is used in all the login steps */}
                    <Button className="mx-1 my-2" disabled={validating}>
                        {validating && <div className={`spinner-border ${darkTheme ? 'text-white' : 'text-blue-gls'}`} role="status" style={{width: '1rem', height: '1rem'}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                        {step === 'sign' ? t("sign-up") : step === 'confirm' ? t("confirm") : t("login")}
                    </Button>
                    {step === 'password' && 
                            <a href="https://api.glsnow.app/accounts/password/reset/" target="_blank">
                                <Button type='button' className="my-2" onClick={ () => window.open('https://api.glsnow.app/accounts/password/reset/', '_blank')}>
                                    {t("forgot-password")}
                                </Button>
                            </a>
                            
                    }
                    {(step === 'email' || step === 'code') &&
                        <>
                            {/* This is the create account option when entering an email */}
                            <h5 className={`${darkTheme && 'text-white'} mt-3 mb-3`}>{t('create-account-label')}</h5>
                            <Button className="me-2" type="button"
                                onClick={() => {setStep('sign'); setError(null)}}
                            >
                                {t("create-account")}
                            </Button>
                        </>
                    }
                </center>
            </form>
        </div>
    )
}

export default withNamespaces()(Login);