import { Link, useNavigate } from "react-router-dom";
import { Banner, VideosList} from "../Components";
import { withNamespaces } from 'react-i18next';
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Home = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const codes = useSelector(state => state.user.codes)
    const navigate = useNavigate()

    useEffect(() => {
        if (codes?.length === 0) navigate("/profile")
    }, [codes])

    return (
        <>
            <Banner />
            <br/>
            {/* borrar ASAP Alerta */}
            <div className="alert alert-success alert-dismissible float-end">
                <small>
                Due to Copyright limitations, Deb Liu’s talk will only be available via GLSNow through August 5, 2024.
                </small>
            </div>
            {/* Borrar ASAP Alerta */}
            <br/>
            <div className="mt-4 ms-3">
                <Link className={`${darkTheme && 'text-white'} GLSFontSecondary text-uppercase`} to='/videos/sessions'><h1>{t("sessions")}</h1></Link>
                <br/>
                <VideosList />
            </div>
            <br/>
            <div className="mt-4 ms-3">
                <Link className={`${darkTheme && 'text-white'} GLSFontSecondary text-uppercase`} to='/videos/grander_vision'><h1>{t("grander-vision")}</h1></Link>
                <br/>
                <VideosList category='grander_vision' />
            </div>
            <br/>
            <div className="mt-4 ms-3">
                <Link className={`${darkTheme && 'text-white'} GLSFontSecondary text-uppercase`} to='/videos/bonus'><h1>{t("bonus-resources")}</h1></Link>
                <br/>
                <VideosList category="bonus" />
            </div>
            <br/>
            
        </>
    )
}

export default withNamespaces()(Home);