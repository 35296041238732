import { withNamespaces } from 'react-i18next';
import { useSelector } from "react-redux";
import greenBar from '../assets/img/barra-profile.png';
import React, { useEffect } from 'react';

const AddContent = ({ t }) => {
    const profile = useSelector(state => state.profile)
    const checkoutData = useSelector(state => state.checkout)
    
    const checkout = checkoutData.CheckoutButton?.sort((a, b) => {
      if ( a.year < b.year ){
        return -1;
      }
      if ( a.year > b.year ){
        return 1;
      }
      return 0;
    });

    console.log(checkout)
    
    const ShopifyBuyInit = (productID, buttonDivID) => {
        var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                ShopifyBuyInitWithID();
            } else {
                loadScript();
            }
        } else {
            loadScript();
        }
        function loadScript() {
            var script = document.createElement('script');
            script.async = true;
            script.src = scriptURL;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
            script.onload = ShopifyBuyInitWithID;
        }
        function ShopifyBuyInitWithID() {
            var client = window.ShopifyBuy.buildClient({
                domain: 'www.lacumbreshop.com',
                storefrontAccessToken: '34b8a2cd3fa218490d1daf0348cd4f48',
            });
            window.ShopifyBuy.UI.onReady(client).then((ui) => {
                ui.createComponent('product', {
                    id: productID,
                    node: document.getElementById(buttonDivID),
                    moneyFormat: '%24%20%7B%7Bamount%7D%7D',
                    options: {
                        "product": {
                          "styles": {
                            "product": {
                              "@media (min-width: 601px)": {
                                "max-width": "100%",
                                "margin-left": "0",
                                "margin-bottom": "50px"
                              },
                              "text-align": "left"
                            },
                            "title": {
                              "font-family": "Montserrat, sans-serif",
                              "font-weight": "normal",
                              "font-size": "26px"
                            },
                            "button": {
                              "border-radius": "30px"
                            },
                            "price": {
                              "font-family": "Montserrat, sans-serif",
                              "font-size": "18px"
                            },
                            "compareAt": {
                              "font-family": "Montserrat, sans-serif",
                              "font-size": "15.299999999999999px"
                            },
                            "unitPrice": {
                              "font-family": "Montserrat, sans-serif",
                              "font-size": "15.299999999999999px"
                            },
                            "description": {
                              "font-family": "Open Sans, sans-serif"
                            }
                          },
                          "buttonDestination": "checkout",
                          "layout": "horizontal",
                          "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true,
                            "description": true
                          },
                          "width": "100%",
                          "text": {
                            "button": "Buy now"
                          },
                          "googleFonts": [
                            "Montserrat",
                            "Open Sans"
                          ]
                        },
                        "productSet": {
                          "styles": {
                            "products": {
                              "@media (min-width: 601px)": {
                                "margin-left": "-20px"
                              }
                            }
                          }
                        },
                        "modalProduct": {
                          "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                          },
                          "styles": {
                            "product": {
                              "@media (min-width: 601px)": {
                                "max-width": "100%",
                                "margin-left": "0px",
                                "margin-bottom": "0px"
                              }
                            },
                            "button": {
                              "border-radius": "30px"
                            },
                            "title": {
                              "font-family": "Helvetica Neue, sans-serif",
                              "font-weight": "bold",
                              "font-size": "26px",
                              "color": "#4c4c4c"
                            },
                            "price": {
                              "font-family": "Helvetica Neue, sans-serif",
                              "font-weight": "normal",
                              "font-size": "18px",
                              "color": "#4c4c4c"
                            },
                            "compareAt": {
                              "font-family": "Helvetica Neue, sans-serif",
                              "font-weight": "normal",
                              "font-size": "15.299999999999999px",
                              "color": "#4c4c4c"
                            },
                            "unitPrice": {
                              "font-family": "Helvetica Neue, sans-serif",
                              "font-weight": "normal",
                              "font-size": "15.299999999999999px",
                              "color": "#4c4c4c"
                            },
                            "description": {
                              "font-family": "Helvetica Neue, sans-serif",
                              "font-weight": "normal",
                              "font-size": "14px",
                              "color": "#4c4c4c"
                            }
                          },
                          "text": {
                            "button": "Add to cart"
                          }
                        },
                        "option": {},
                        "cart": {
                          "styles": {
                            "button": {
                              "border-radius": "30px"
                            }
                          },
                          "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                          }
                        },
                        "toggle": {}
                    },
                });
            });
        }
    }

    useEffect(() => {
        checkout?.forEach((btn) => {
            ShopifyBuyInit(btn.productID, btn.buttonCode);
        })
    }, [checkout]);

    return (
        <>
            <div className="card py-2 mx-4 mt-4">
                <div className="d-flex align-items-center justify-content-center mb-3">
                    <h2 className='col-3'>{profile.first_name + ' ' + profile.last_name}</h2>
                    <b className='col-5 text-center'>{t('active-codes')}</b>
                    <div className='col-3 d-none d-lg-flex justify-content-end'><img src={greenBar} alt="green-bar"/></div>
                </div>
                <div className="d-flex justify-content-around">
                    {profile.years.map((year, index) => <b key={index} className='text-info'>{year}</b>)}
                </div>
            </div>

            <div className='d-block d-sm-flex align-items-center justify-content-start p-4 my-4'>
                <h1 className='text-secondary col-12 text-center text-sm-start col-sm-4'>{t('get-content')}</h1>
                <h5 className='text-secondary text-center col-12 col-sm-4'>{t('available-collections')}</h5>
            </div>

            <div className="d-flex flex-wrap justify-content-center">
                {checkout?.map((btn) => 
                    <div key={btn.name} className='col-11 col-md-5 col-lg-4'>
                        <div className="card p-3 m-4 mt-0 h-100">
                            <b className='text-info text-end'>{btn.year}</b>
                            <div id={btn.buttonCode}></div>
                        </div>
                    </div>
                )}
                {!checkout &&  
                    <div className='col-11 col-md-6 col-lg-4' style={{minHeight: '500px'}}>
                        <div className="card py-3">
                            <b className='text-center m-auto'>{t('not-available')}</b>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default withNamespaces()(AddContent);