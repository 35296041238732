import { withNamespaces } from 'react-i18next';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from 'react-redux';


const NotFound = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        document.body?.classList.remove('bg-dark');
        document.body?.classList.remove('bg-landing');
        document.body?.classList.add('bg-login');
    });

    return (
        <div className='main404 pt-5'>
            <h1 className={`${darkTheme && 'text-white'} GLSFontSecondary mt-5`} style={{ fontSize: '12em', fontWeight: 'lighter'}}>404 </h1>
            <h2 className={`${darkTheme && 'text-white'} GLSFontSecondary pt-4`}>
                {t("page-not-found")}
            </h2>
            <h6 className={`${darkTheme && 'text-white'} fst-italic pt-2`}>
                {t("go-back")} <Link className={`${darkTheme && 'text-white'} text-decoration-underline`} to="/home"> {t("homepage")} </Link>
            </h6>
        </div>
    )
}

export default withNamespaces()(NotFound);