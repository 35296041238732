import { getCheckoutButtons } from '../../services';

export const initCheckout = (token) => {
    return async (dispatch) => {
        const checkout = await getCheckoutButtons(token)

        dispatch({
            type: 'SET_CHECKOUT',
            payload: checkout
        })
    }
}