import PropTypes from 'prop-types'
import Select from "react-select";

export const Selector = ({options, onChange, selected, placeholder, textColor}) => {
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%'
        }),

        menu: (provided) => ({
            ...provided,
            backgroundColor: '#154B69',
            maxHeight: '300px',
            overflow: 'auto'
        }),
        
        option: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'white'
        }),

        control: (provided) => ({
            ...provided,
            backgroundColor: 'transparent',
            marginBottom: '1.5rem'
        }),

        singleValue: (provided) => ({
            ...provided,
            color: selected ? textColor : 'gray',
            textAlign: 'left'
        })
    }

    const defaultHandler = () => {
        if (selected) return options.filter((option) => option.value === selected)[0]
        if (placeholder) return {label: placeholder, value: ''}
        return options[0]
    }

    return (
        <Select
            styles={customStyles}
            defaultValue={defaultHandler()}
            options={options}
            onChange={({value}) => onChange(value)}
        />
    )
}

Selector.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
    })).isRequired,
    selection: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    textColor: PropTypes.string
}

Selector.defaultProps = {
    selection: null,
    placeholder: null,
    textColor: 'white'
}