import axios from 'axios';
import { config } from './config'

const checkoutURL = config.server + '/v3/media/GET/user_checkoutbuttons/'

export const getCheckoutButtons = async (token) => {
    try {
        const {data} = await axios.get(checkoutURL + token.id + '/')
        return data
    } catch (e) {
        return e
    }
}